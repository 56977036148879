export const EVENTS = {
	KSYS_APP_LOADED: 'ksys-app-loaded',
	KSYS_APPS_LOADED: 'ksys-apps-loaded',
	KSYS_APP_EMBED_ADDED: 'ksys-app-embed-added',
	KSYS_APP_MANAGER_INITIALIZED: 'ksys-app-manager-initialized',
	KSYS_APP_PREVIEW_UPDATED: 'ksys-app-preview-updated',
};
export function triggerEvent(name: string, dispatcher: { dispatchEvent: (event: Event) => boolean } = document) {
	console.info(`event triggered: ${ name }`);
	const event = document.createEvent('Event');
	event.initEvent(name, true, true);
	dispatcher.dispatchEvent(event);
}
(window as any).ksysTriggerEvent = triggerEvent;
if (window.parent) {
	(window.parent as any).ksysTriggerEvent = triggerEvent;
	(window.parent as any).childDocument = document;
	(window.parent as any).childWindow = window;
}