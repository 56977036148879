import { StoreContext } from '../../../configuration/StoreContext';
import { KsysApiClient } from '@kurtosys/ksys-api-client/dist/KsysApiClient';
import { observable, reaction, action, computed } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { GetApplicationAppConfig } from '@kurtosys/ksys-api-client/dist/requests/root/GetApplicationAppConfig';
import { IRequestOptions } from '@kurtosys/ksys-api-client/dist/models/IRequestOptions';
import { ListTranslations } from '@kurtosys/ksys-api-client/dist/requests/config/ListTranslations';
import { IQueryContextClient } from '@kurtosys/ksys-api-client/dist/models/query/IQueryContextClient';
import { ListCommentaries } from '@kurtosys/ksys-api-client/dist/requests/config/ListCommentaries';
import { ListDisclaimers } from '@kurtosys/ksys-api-client/dist/requests/config/ListDisclaimers';
import { SearchDocuments } from '@kurtosys/ksys-api-client/dist/requests/documents/SearchDocuments';
import { SearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SearchEntity';
import { GetTheme } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetTheme';
import { GetApplicationClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/applicationManager/GetApplicationClientConfiguration';
import { RetrieveClientConfiguration } from '@kurtosys/ksys-api-client/dist/requests/config/RetrieveClientConfiguration';
import { SavedSearchEntity } from '@kurtosys/ksys-api-client/dist/requests/fund/SavedSearchEntity';
import { RetrieveDocument } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocument';
import { Retrieve } from '@kurtosys/ksys-api-client/dist/requests/documents/Retrieve';
import { RetrieveDocumentZip } from '@kurtosys/ksys-api-client/dist/requests/documents/RetrieveDocumentZip';
import { GetUserByToken } from '@kurtosys/ksys-api-client/dist/requests/auth/getUserByToken';
import { LogoutProxy } from '@kurtosys/ksys-api-client/dist/requests/auth/LogoutProxy';
import { StopImpersonating } from '@kurtosys/ksys-api-client/dist/requests/auth/StopImpersonating';
import { GetFilters } from '@kurtosys/ksys-api-client/dist/requests/fund/GetFilters';
import { CheckLoggedIn } from '@kurtosys/ksys-api-client/dist/requests/auth/CheckLoggedIn';

export class KurtosysApiStore extends StoreBase {
	@observable token: string = '';
	private clientInstance: KsysApiClient | undefined;
	@computed
	get wrappedToken(): { value: string } {
		return { value: this.token };
	}

	get serviceUrl(): string {
		const { appStore } = this.storeContext;
		if (appStore.authentication && appStore.authentication.serviceUrl && appStore.authentication.serviceUrl.length > 0) {
			return appStore.authentication.serviceUrl;
		}
		if (appStore.configuration && appStore.configuration.core && appStore.configuration.core.serviceUrl) {
			return appStore.configuration.core.serviceUrl;
		}
		return this.getBaseAddress();
	}

	@computed
	get options(): IRequestOptions<any> {
		return {
			baseUrl: this.serviceUrl,
		};
	}

	@computed
	get proxyOptions(): IRequestOptions<any> {
		return {
			baseUrl: this.getBaseAddress(),
			disableCachableRequests: true,
		};
	}

	constructor(storeContext: StoreContext) {
		super(storeContext);
	}

	@computed
	get client(): IQueryContextClient {
		const token = this.wrappedToken;
		const options = this.options;
		const response: IQueryContextClient = {
			config: {
				clientConfiguration: {
					retrieve: new RetrieveClientConfiguration(token, options),
				},
			},
			commentaries: {
				list: new ListCommentaries(token, options),
			},
			disclaimers: {
				list: new ListDisclaimers(token, options),
			},
			documents: {
				search: new SearchDocuments(token, options),
				retrieve: new RetrieveDocument(token, options),
				retrieveByProperty: new Retrieve(token, options),
				retrieveZip: new RetrieveDocumentZip(token, options),
			},
			entity: {
				search: new SearchEntity(token, options),
				savedSearchEntity: new SavedSearchEntity(token, options),
			},
			options: {
				baseUrl: this.serviceUrl,
			},
		};
		return response;
	}

	@computed
	get getAppConfig(): GetApplicationAppConfig {
		return new GetApplicationAppConfig(this.wrappedToken, this.options);
	}

	@computed
	get listTranslations(): ListTranslations {
		return new ListTranslations(this.wrappedToken, this.options);
	}

	@computed
	get getTheme(): GetTheme {
		return new GetTheme(this.wrappedToken, this.options);
	}

	@computed
	get getUserByToken(): GetUserByToken {
		return new GetUserByToken(undefined, this.proxyOptions);
	}

	@computed
	get stopImpersonating(): StopImpersonating {
		return new StopImpersonating(undefined, this.proxyOptions);
	}

	@computed
	get logout(): LogoutProxy {
		return new LogoutProxy(undefined, this.proxyOptions);
	}

	@computed
	get checkLoggedIn(): CheckLoggedIn {
		return new CheckLoggedIn(undefined, this.proxyOptions);
	}

	@computed
	get getApplicationClientConfiguration(): GetApplicationClientConfiguration {
		return new GetApplicationClientConfiguration(this.wrappedToken, this.options);
	}

	@computed
	get getFilters(): GetFilters {
		return new GetFilters(this.wrappedToken, this.options);
	}

	@action
	async initialize(): Promise<void> {

	}

	@computed
	get appParamsHelper() {
		return this.storeContext && this.storeContext.appStore && this.storeContext.appStore.appParamsHelper;
	}

	getBaseAddress(suffix: string = 'services/') {
		return this.manifest.getBaseUrl(suffix, this.appParamsHelper);
	}

}