import { IConfiguration } from '../models/app/IConfiguration';

export const CONFIGURATION: IConfiguration = {
	components: {
		app: {
			displayType: 'buttons',
			redirects: {
				profile: {
					defaultUrl: '/profile.html?user={user.userName}',
				},
				logout: {
					defaultUrl: '/logged-out.html',
				},
				home: {
					defaultUrl: '/',
				},
			},
		},
		userProfileLabel: {
			label: 'email',
			icon: {
				type: 'initials',
				position: 'right',
			},
		},
		userProfileButton: {
			tooltipProps: {
				content: 'Go to user profile',
				trigger: 'hover',
				preserveClickAction: true,
			},
			tooltipPropsStopImpersonating: {
				content: 'Stop impersonating user',
				trigger: 'hover',
				preserveClickAction: true,
			},
		},
		logoutButton: {
			tooltipProps: {
				content: 'Sign out',
				trigger: 'hover',
				preserveClickAction: true,
			},
		},
		dropdownMenu: {
			items: [
				{
					action: 'go-to-profile',
					label: 'Change Password',
				},
				{
					action: 'stop-impersonating',
					label: 'Stop Impersonating',
				},
				{
					action: 'logout',
					label: 'Log Out',
				},
			],
		},
	},
};
