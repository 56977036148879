import React, { Component, Suspense, lazy } from 'react';
import { IAppProps } from './models/IAppProps';
import { IAppState } from './models/IAppState';
import { inject, observer } from 'mobx-react';
import Wrapper from './styledComponents/Wrapper';
import ButtonWrapper from './styledComponents/ButtonWrapper';
import DebugInfoAction from '../shared/DebugInfoAction';
import NoDataPlaceholder from '@kurtosys/ksys-app-components/dist/components/base/NoDataPlaceholder';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Loading from '../shared/Loading';
import SkeletonLoader from '../shared/SkeletonLoader';
import Application from '@kurtosys/ksys-app-components/dist/components/base/Application';
import UserProfileButton from '../UserProfileButton/UserProfileButton';
import LogoutButton from '../LogoutButton/LogoutButton';
import DropdownMenu from '../DropdownMenu/DropdownMenu';

@inject('appStore', 'queryStore')
@observer
export class App extends Component<IAppProps, IAppState> {
	static configurationKey: 'app' = 'app';
	static styleKey: 'app' = 'app';
	constructor(props: IAppProps) {
		super(props);
	}
	render() {
		const { className, appStore, selector, queryStore } = this.props;
		if (!appStore || !queryStore) {
			return null;
		}
		const { isBootstrapped, skeletonLoader, skeletonLoaders } = appStore;
		if (!isBootstrapped) {
			if (skeletonLoader) {
				return <SkeletonLoader config={ skeletonLoader } variables={ skeletonLoaders && skeletonLoaders.variables } />;
			}
			return <Loading />;
		}
		const { theme, show, libraryComponentsConfiguration, getTranslateFunction, assets, assetCacheOptions, assetRegisters, displayType } = appStore;

		if (!show) {
			return null;
		}
		const translate = getTranslateFunction();
		return (
			<Application theme={ theme } configuration={ libraryComponentsConfiguration } translate={ translate } assets={ assets } assetCacheOptions={ assetCacheOptions } assetRegisters={ assetRegisters }>
				<DebugInfoAction appStore={ appStore } />
				<Wrapper className={ className }>
					{
						displayType === 'buttons' && (
							<ButtonWrapper>
								<UserProfileButton />
								<LogoutButton />
							</ButtonWrapper>
						)
					}
					{
						displayType === 'dropdown-menu' && <DropdownMenu />
					}
				</Wrapper>
			</Application>
		);
	}
}

export default InjectedStyledComponent(App, 'app');
