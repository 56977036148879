import React, { Component, Fragment } from 'react';
import {
	ILogoutButtonProps,
	ILogoutButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import Icon from './styledComponents/Icon';
import Tooltip from '@kurtosys/ksys-app-components/dist/components/base/Tooltip';
import { utils } from '@kurtosys/ksys-app-template/dist';
import { computed } from 'mobx';

@inject('appStore', 'logoutButtonStore')
@observer
export class LogoutButton extends Component<ILogoutButtonProps, ILogoutButtonState> {
	static configurationKey: 'logoutButton' = 'logoutButton';
	static styleKey: 'logoutButton' = 'logoutButton';
	constructor(props: ILogoutButtonProps) {
		super(props);
	}

	@computed
	get label() {
		const { logoutButtonStore, appStore } = this.props;

		if (!logoutButtonStore || !appStore) {
			return null;
		}

		const { label, showIcon, iconPosition } = logoutButtonStore;
		const icon = <Icon src={ 'Logout.svg' } />;
		return (
			<Fragment>
				{ showIcon && iconPosition === 'left' && icon }
				{ label }
				{ showIcon && iconPosition === 'right' && icon }
			</Fragment>
		);
	}

	render() {
		const { className, logoutButtonStore, appStore } = this.props;

		if (!logoutButtonStore || !appStore) {
			return null;
		}

		const { tooltipProps } = logoutButtonStore;
		const { logout } = appStore;
		const hasTooltip = !utils.typeChecks.isNullOrUndefined(tooltipProps);

		return (
			<Wrapper className={ className } onClick={ logout }>
				{ !hasTooltip && this.label }
				{ hasTooltip && <Tooltip { ...tooltipProps } >{ this.label }</Tooltip> }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(LogoutButton, 'logoutButton');

