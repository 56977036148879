import { AppStoreBase } from './AppStoreBase';
import { IAppComponents } from '../models/IAppComponents';
import { computed, action, observable } from 'mobx';
import { StoreContext } from '../../../configuration/StoreContext';
import { Manifest } from '../../../configuration/Manifest';
import { TGetUserByTokenResponseBody } from '@kurtosys/ksys-api-client/dist/models/requests/auth/TGetUserByTokenResponseBody';
import { TDisplayType } from '../models/TDisplayType';
import { utils, helpers } from '@kurtosys/ksys-app-template';
import { IRedirect } from '../../../models/commonTypes';
import { number } from '@kurtosys/ksys-app-template/dist/utils';

/* [Component: appStoreComponentImport] */

export type TUserProperty = 'email' | 'userName' | 'name' | 'impersonatorUsername';

export class AppStore extends AppStoreBase {
	@observable.ref public user: TGetUserByTokenResponseBody | undefined;

	constructor(element: HTMLElement, url: string, storeContext: StoreContext, manifest: Manifest) {
		super(element, url, storeContext, manifest);
		this.getUser();
		this.initializeCheckLoggedInInterval();
	}

	checkLoggedInInterval: NodeJS.Timeout | undefined;

	@computed
	get checkLoggedInIntervalTime(): number {
		return ((this.appComponentConfiguration && this.appComponentConfiguration.checkLoggedInIntervalTime) || 60) * 1000;
	}

	@action
	clearCheckLoggedInInterval = () => {
		if (this.checkLoggedInInterval) {
			clearInterval(this.checkLoggedInInterval);
		}
	}

	@action
	initializeCheckLoggedInInterval = () => {
		this.clearCheckLoggedInInterval();
		if (this.mustCheckLoggedIn) {
			this.checkLoggedInInterval = setInterval(() => {
				this.checkLoggedIn();
			}, this.checkLoggedInIntervalTime);
		}
		else {
			console.info('checkLoggedIn interval disabled');
		}
	}

	@computed
	get hasData(): boolean {
		return !utils.typeChecks.isNullOrUndefined(this.user);
	}

	@computed
	get components(): IAppComponents {
		return {
			/* [Component: appStoreComponent] */
		};
	}

	getUserValue(key: TUserProperty): string {
		return this.user && this.user[key] || '';
	}

	@computed
	get displayType(): TDisplayType {
		return this.appComponentConfiguration && this.appComponentConfiguration.displayType || 'buttons';
	}

	@computed
	get homeRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.home;
	}

	@computed
	get logoutRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.logout;
	}

	@computed
	get profileRedirect(): IRedirect | undefined {
		return this.appComponentConfiguration && this.appComponentConfiguration.redirects && this.appComponentConfiguration.redirects.profile;
	}

	@action
	getUser = async () => {
		if (!this.user) {
			const { kurtosysApiStore } = this.storeContext;
			try {
				this.user = await kurtosysApiStore.getUserByToken.execute();
			}
			catch (ex) {
				console.warn(ex);
			}
		}
	}

	@computed
	get isImpersonationActive(): boolean {
		return !utils.typeChecks.isNullOrEmpty(this.getUserValue('impersonatorUsername'));
	}

	@action
	stopImpersonating = async () => {
		const { kurtosysApiStore } = this.storeContext;
		try {
			await kurtosysApiStore.stopImpersonating.execute();
			this.redirectTo(this.homeRedirect);
		}
		catch (ex) {
			console.warn(ex);
		}
	}

	@action
	logout = async () => {
		const { kurtosysApiStore } = this.storeContext;
		try {
			this.clearCheckLoggedInInterval();
			await kurtosysApiStore.logout.execute();
		}
		catch (ex) {
			console.warn(ex);
		}
		this.redirectTo(this.logoutRedirect);
	}

	@computed
	get mustCheckLoggedIn() {
		// Don't check logged in while editing in elementor
		return !/\/wp-admin\/post.php\?post=(\d*)\&action=elementor/.test(top.location.href);
	}

	@action
	checkLoggedIn = async () => {
		const { kurtosysApiStore } = this.storeContext;
		if (this.mustCheckLoggedIn) {
			try {
				const response = await kurtosysApiStore.checkLoggedIn.execute();
				if (!response || !response.loggedIn) {
					this.logout();
				}
			}
			catch (ex) {
				console.warn(ex);
				this.logout();
			}
		}
		else {
			console.log('skipped checkLoggedIn');
		}
	}

	@action
	goToProfile = async () => {
		this.redirectTo(this.profileRedirect);
	}

	public redirectTo(redirect?: IRedirect) {
		console.log({ redirect });
		if (!utils.typeChecks.isNullOrEmpty(redirect)) {
			const { defaultUrl, base } = redirect;
			const redirectHelper = new helpers.RedirectHelper(defaultUrl, base);
			const payload = {
				user: this.user,
			};
			redirectHelper.go(redirect, payload);
		}
	}
}