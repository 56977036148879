import { TemplateStore } from '../components/App/stores/TemplateStore';
import { AppStore } from '../components/App/stores/AppStore';
import { KurtosysApiStore } from '../components/App/stores/KurtosysApiStore';
import { TranslationStore } from '../components/App/stores/TranslationStore';
import { QueryStore } from '../components/App/stores/QueryStore';
import { Manifest } from './Manifest';
import { UserProfileButtonStore } from '../components/UserProfileButton/stores/UserProfileButtonStore';
import { LogoutButtonStore } from '../components/LogoutButton/stores/LogoutButtonStore';
import { DropdownMenuStore } from '../components/DropdownMenu/stores/DropdownMenuStore';
import { UserProfileLabelStore } from '../components/UserProfileLabel/stores/UserProfileLabelStore';
/* [Component: storesImport] */

export class StoreContext {
	templateStore: TemplateStore;
	appStore: AppStore;
	kurtosysApiStore: KurtosysApiStore;
	translationStore: TranslationStore;
	queryStore: QueryStore;
	userProfileButtonStore: UserProfileButtonStore;
	logoutButtonStore: LogoutButtonStore;
	dropdownMenuStore: DropdownMenuStore;
	userProfileLabelStore: UserProfileLabelStore;
	/* [Component: storeVariable] */
	constructor(element: HTMLElement, url: string, templateStore: TemplateStore, public manifest: Manifest) {
		this.templateStore = templateStore;
		this.kurtosysApiStore = new KurtosysApiStore(this);
		this.translationStore = new TranslationStore(this);
		this.queryStore = new QueryStore(this);
		this.appStore = new AppStore(element, url, this, manifest);
		this.userProfileButtonStore = new UserProfileButtonStore(this);
		this.logoutButtonStore = new LogoutButtonStore(this);
		this.dropdownMenuStore = new DropdownMenuStore(this);
		this.userProfileLabelStore = new UserProfileLabelStore(this);
		/* [Component: storeAssignment] */
		this.initialize();
	}
	initialize() {
		this.appStore.initialize();
	}
	get providerProps() {
		return {
			appStore: this.appStore,
			kurtosysApiStore: this.kurtosysApiStore,
			translationStore: this.translationStore,
			queryStore: this.queryStore,
			userProfileButtonStore: this.userProfileButtonStore,
			logoutButtonStore: this.logoutButtonStore,
			dropdownMenuStore: this.dropdownMenuStore,
			userProfileLabelStore: this.userProfileLabelStore,
			/* [Component: storeProviderProps] */
		};
	}

	// TODO: NEED TO MERGE STORES
	registerStore(key: string, store: any) {

	}
}