import * as packageJson from '../../package.json';
import { helpers } from '@kurtosys/ksys-app-template';
import { IPackageConfig } from '../models/app/IPackageConfig';
import { IPackageConfigEnvironments } from '../models/app/IPackageConfigEnvironments.js';
import { TExternalScript } from '../models/commonTypes';
import { getAppHydration, getManagerHydration } from '../utils/initialize';
import { getHydrationPoint } from '../initialize';
import { appendCacheQueryString, getCurrentScript } from '../utils/scripts';

export class Manifest {
	get isDevelopment(): boolean {
		return process.env.NODE_ENV === 'development';
	}
	get version(): string {
		return packageJson.version;
	}
	get packageName(): string | undefined {
		return packageJson.name;
	}
	get packageConfig(): IPackageConfig {
		return (packageJson as any)['config'] || {};
	}
	get proxy(): string | undefined {
		return (packageJson as any)['proxy'];
	}
	get alias(): string | undefined {
		return this.packageConfig.alias;
	}
	get description(): string | undefined {
		return this.packageConfig.description;
	}
	get testEnvironments(): IPackageConfigEnvironments {
		return this.packageConfig.testEnvironments;
	}
	get bypassAppManager(): boolean {
		// tslint:disable-next-line: no-boolean-literal-compare
		return this.packageConfig.bypassAppManager === true;
	}
	get ksysAppTemplateId(): string {
		return `${ this.packageName }`;
	}
	get storeKey(): string {
		return `${ this.ksysAppTemplateId }-v${ this.version }-store`;
	}
	get externalScripts(): TExternalScript[] {
		const scripts: TExternalScript[] = [];
		if (!this.isDevelopment) {
			scripts.push(
				'vendor-mobx.4.9.4-react.16.8.6-react-dom.16.8.6-styled-components.4.2.0.min.js',
			);
			const { peerDependencies } = packageJson;
			const templatePackageKey = '@kurtosys/ksys-app-template';
			const templateVersion = peerDependencies[templatePackageKey];
			if (templateVersion) {
				scripts.push(`ksys-app-template-v${ templateVersion }.min.js`);
			}
		}

		// Polyfill URL API
		if (typeof window.URL !== 'function') {
			scripts.push('https://unpkg.com/url-polyfill@1.1.5/url-polyfill.js');
		}

		const managerAppResponse = getManagerHydration();
		const managerAssetCacheOptions = (
			managerAppResponse &&
			managerAppResponse.applicationConfiguration &&
			managerAppResponse.applicationConfiguration.core &&
			managerAppResponse.applicationConfiguration.core.assetCacheOptions
		);
		if (managerAssetCacheOptions) {
			scripts.forEach((script, index) => {
				if (typeof script === 'string') {
					scripts[index] = appendCacheQueryString(script, undefined, managerAssetCacheOptions);
				}
				else {
					script.src = appendCacheQueryString(script.src, undefined, managerAssetCacheOptions);
				}
			});
		}

		return scripts;
	}

	getBaseUrlPrefix(appParamsHelper?: helpers.AppParamsHelper<any, any, any>) {
		let response: string = '';

		// define prefix based on hydration
		const hydrationPoint = getHydrationPoint();
		if (hydrationPoint && hydrationPoint.javascript && appParamsHelper) {
			const managerAppResponse = getManagerHydration();
			const appResponse = getAppHydration(this, appParamsHelper);
			if (appResponse && managerAppResponse) {
				const { app } = appResponse;
				const { applicationCode } = app;
				const { templateConfiguration } = managerAppResponse;
				const host = templateConfiguration && templateConfiguration.externalScriptsBasePath;
				return `${ host }/applicationManager/apps/${ applicationCode }`;
			}
		}

		// else determine prefix based on script
		const scriptFilename = `/main-${ this.packageName }.js`;
		const currentScript = getCurrentScript(this);
		const url = currentScript && currentScript.src;
		if (url) {
			const index = url.indexOf(scriptFilename);
			if (index > -1) {
				response = `${ url.substr(0, index) }`;
			}
		}

		return response;
	}

	getBaseUrl(suffix: string = 'services/', appParamsHelper?: helpers.AppParamsHelper<any, any, any>) {
		return `${ this.getBaseUrlPrefix(appParamsHelper) }/${ suffix }`;
	}

	getApplicationGuid(appParamsHelper?: helpers.AppParamsHelper<any, any, any>): string | undefined {
		const applicationUrlPath = this.isDevelopment ? this.proxy : this.getBaseUrlPrefix(appParamsHelper);
		if (applicationUrlPath) {
			const proxyParts = applicationUrlPath.split('/');
			if (proxyParts.length > 0) {
				return proxyParts[proxyParts.length - 1];
			}
		}
	}

	serialize(): object {
		const keys: (keyof Manifest)[] = [
			'alias',
			'bypassAppManager',
			'description',
			'externalScripts',
			'getBaseUrl',
			'ksysAppTemplateId',
			'packageConfig',
			'packageName',
			'storeKey',
			'version',
		];
		const response: any = {};
		keys.forEach((key) => {
			response[key] = this[key];
		});

		console.log({ response });
		return response;
	}
}
