import React, { Component } from 'react';
import {
	IUserProfileButtonProps,
	IUserProfileButtonState,
} from './models';
import { inject, observer } from 'mobx-react';
import { InjectedStyledComponent } from '../shared/InjectedStyledComponent';
import Wrapper from './styledComponents/Wrapper';
import UserProfileLabel from '../UserProfileLabel/UserProfileLabel';
import Tooltip from '@kurtosys/ksys-app-components/dist/components/base/Tooltip';
import { utils } from '@kurtosys/ksys-app-template/dist';
import { Icon } from '@kurtosys/ksys-app-components/dist/components/base/Icon/Icon';
import StopImpersonatingButton from './styledComponents/StopImpersonatingButton';
import ProfileButton from './styledComponents/ProfileButton';
import { computed } from 'mobx';

@inject('appStore', 'userProfileButtonStore')
@observer
export class UserProfileButton extends Component<IUserProfileButtonProps, IUserProfileButtonState> {
	static configurationKey: 'userProfileButton' = 'userProfileButton';
	static styleKey: 'userProfileButton' = 'userProfileButton';
	constructor(props: IUserProfileButtonProps) {
		super(props);
	}

	@computed
	get stopImpersonatingButton() {
		const { userProfileButtonStore, appStore } = this.props;

		if (!userProfileButtonStore || !appStore) {
			return null;
		}

		const { stopImpersonating } = appStore;
		const { tooltipPropsStopImpersonating } = userProfileButtonStore;

		if (!utils.typeChecks.isNullOrUndefined(tooltipPropsStopImpersonating)) {
			return (
				<Tooltip { ...tooltipPropsStopImpersonating }>
					<StopImpersonatingButton onClick={ stopImpersonating }>
						<Icon onClick={ stopImpersonating } src="StopImpersonating.svg" />
					</StopImpersonatingButton>
				</Tooltip>
			);
		}

		return (
			<StopImpersonatingButton onClick={ stopImpersonating }>
				<Icon onClick={ stopImpersonating } src="StopImpersonating.svg" />
			</StopImpersonatingButton>
		);

	}

	render() {
		const { className, userProfileButtonStore, appStore } = this.props;

		if (!userProfileButtonStore || !appStore) {
			return null;
		}
		const { goToProfile, isImpersonationActive } = appStore;
		const { tooltipProps } = userProfileButtonStore;

		const hasTooltip = !utils.typeChecks.isNullOrUndefined(tooltipProps);

		return (
			<Wrapper className={ className }>
				<ProfileButton onClick={ goToProfile }>
					{ !hasTooltip && <UserProfileLabel /> }
					{ hasTooltip && <Tooltip { ...tooltipProps } ><UserProfileLabel /></Tooltip> }
				</ProfileButton>
				{ isImpersonationActive && this.stopImpersonatingButton }
			</Wrapper>
		);
	}
}

export default InjectedStyledComponent(UserProfileButton, 'userProfileButton');
