import { IStyles } from '../models/app/IStyles';

export const STYLES: IStyles = {
	components: {
		app: {
			children: {
				wrapper: {
					overrides: [
						{
							selector: 'section',
							style: {
								display: 'flex',
							},
						},
					],
					background: {
						color: 'rgb(26, 128, 255)',
					},
					color: 'white',
					position: 'absolute',
					top: '0',
					left: '0',
					width: '100%',
					display: 'flex',

				},
				buttonWrapper: {
					display: 'flex',
					margin: {
						left: 'auto',
					},
				},
			},
		},
		dropdownMenu: {
			children: {
				wrapper: {
					text: {
						decoration: 'underline',
					},
					padding: {
						bottom: '6px',
					},
					display: 'flex',
					margin: {
						left: 'auto',
					},
				},
			},
		},
		userProfileButton: {
			children: {
				wrapper: {
					cursor: 'pointer',
					padding: {
						_value: '12px',
					},
					selectors: {
						':hover': {
							background: {
								color: 'rgb(19, 117, 241)',
							},
						},
					},
				},
				stopImpersonatingButton: {
					padding: {
						top: '5px',
						left: '5px',
					},
				},
			},
		},
		logoutButton: {
			margin: {
				_value: 'auto',
			},
			children: {
				wrapper: {
					cursor: 'pointer',
					padding: {
						_value: '12px',
					},
					selectors: {
						':hover': {
							background: {
								color: 'rgb(19, 117, 241)',
							},
						},
					},
					display: 'flex',
				},
				icon: {
					width: '20px',
					height: '20px',
					margin: {
						_value: '0 4px',
					},
				},
			},
		},
		userProfileLabel: {
			children: {
				wrapper: {
					display: 'flex',
					children: {
					},
				},
				iconWrapper: {
					height: '100%',
					margin: {
						_value: 'auto',
					},
				},
				impersonatedUserLabel: {
					font: {
						weight: 'bold',
					},
				},
				initials: {
					border: {
						radius: '50%',
					},
					font: {
						size: '11px',
						weight: 'bold',
					},
					margin: {
						left: '6px',
					},
					width: '20px',
					height: '20px',
					lineHeight: '20px',
					text: {
						align: 'center',
					},
					background: {
						color: 'white',
					},
					color: 'rgb(26, 128, 255)',
				},
				icon: {
					width: '20px',
					height: '20px',
					margin: {
						_value: '0 4px',
					},
				},
				label: {
					lineHeight: '20px',
				},
			},
		},

	},
	theme: {
		base: {
			application: {
				font: {
					family: '"DM Sans", sans-serif',
					size: '13px',
					weight: '400',
				},
			},
			contextMenu: {
				children: {
					wrapper: {
						padding: {
							_value: '10px 10px 4px 10px',
						},
					},
					button: {
						border: {
							style: 'none',
						},
						outline: {
							style: 'none',
						},
						font: {
							size: '13px',
						},
						lineHeight: '20px',
						display: 'flex',
					},
					icon: {
						display: 'none',
					},
					dropdown: {
						margin: {
							top: '9px',
						},
						border: {
							color: 'rgb(26, 128, 255)',
							style: 'solid',
							width: 'thin',
						},
						position: 'absolute',
						right: '0',
						min: {
							width: '200px',
						},
						background: {
							color: 'white',
						},
					},
					dropdownItem: {
						color: 'black',
						display: 'block',
						font: {
							size: '13px',
						},
						padding: {
							left: '5px',
							right: '5px',
						},
						lineHeight: '34px',
						selectors: {
							':hover': {
								background: {
									color: 'rgb(26, 128, 255)',
								},
								color: '#FFF',
							},
						},
					},
					clickLayer: {
						position: 'fixed',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						background: {
							color: 'transparent',
						},
					},
				},
			},
			tooltip: {
				children: {
					wrapper: {
						display: 'inline-block',
						verticalAlign: 'text-bottom',
						overrides: [
							{
								selector: '[data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									position: 'absolute',
								},
							},
							{
								selector: 'div[data-placement="top"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									bottom: '-14px',
								},
							},
							{
								selector: 'div[data-placement="bottom"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									top: '-13px',
									transform: 'rotate(180deg)',
								},
							},
							{
								selector: 'div[data-placement="left"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									right: '-11px',
									transform: 'rotate(-90deg)',
								},
							},
							{
								selector: 'div[data-placement="right"] [data-selector="base-tooltip-arrowIconWrapper"]',
								style: {
									left: '-11px',
									transform: 'rotate(90deg)',
								},
							},
						],
					},
					tooltipIconButton: {
						cursor: 'pointer',
						position: 'sticky',
					},
					tooltipCharacter: {
						font: {
							size: '9px',
						},
						min: {
							width: '1.5em',
						},
						margin: {
							_value: '0 0.5em',
						},
						padding: {
							top: '0.2em',
							bottom: '0.2em',
							left: '0.5em',
							right: '0.5em',
						},
						color: 'white',
						background: {
							color: '#1EA7FD',
						},
						border: {
							radius: '1.5em',
						},
						text: {
							align: 'center',
						},
					},
					tooltipIcon: {
						width: '16px',
						height: '16px',
					},
					tooltipContent: {
						max: {
							width: '250px',
						},
						background: {
							color: 'white',
						},
						color: 'black',
						border: {
							style: 'solid',
							width: '1px',
							color: '#cccccc',
							radius: '3px',
						},
						box: {
							shadow: '2px 2px 0 rgba(0,0,0,0.1)',
							sizing: 'border-box',
						},
						padding: {
							_value: '4px',
						},
						font: {
							size: 'smaller',
						},
						text: {
							align: 'center',
						},
						margin: {
							_value: '3px',
							bottom: '2px',
						},
					},
					clickLayer: {
						position: 'fixed',
						top: '0',
						bottom: '0',
						left: '0',
						right: '0',
						background: {
							color: 'transparent',
						},
					},
					arrowIcon: {
						fill: 'white',
						stroke: '#cccccc',
					},
				},
			},
		},
	},
};