import { computed, action } from 'mobx';
import { StoreBase } from '../../../common/StoreBase';
import { ILogoutButtonConfiguration } from '../models';
import { TIconPosition } from '../../../models/TIconPosition';
import { ITooltipProps } from '@kurtosys/ksys-app-components/dist/components/base/Tooltip/models';

export class LogoutButtonStore extends StoreBase {
	static componentKey: 'logoutButton' = 'logoutButton';

	@computed
	get configuration(): ILogoutButtonConfiguration | undefined {
		if (this.storeContext && this.storeContext.appStore) {
			return this.storeContext.appStore.getComponentConfiguration(LogoutButtonStore.componentKey);
		}
	}

	@computed
	get label(): string {
		return this.configuration && this.configuration.label || '';
	}

	@computed
	get showIcon(): boolean {
		return this.configuration && this.configuration.icon && this.configuration.icon.show || true;
	}

	@computed
	get iconPosition(): TIconPosition {
		return this.configuration && this.configuration.icon && this.configuration.icon.position || 'right';
	}

	@computed
	get tooltipProps(): ITooltipProps | undefined {
		return this.configuration && this.configuration.tooltipProps;
	}

	@action
	async initialize(): Promise<void> {

	}
}