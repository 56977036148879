import { models } from '@kurtosys/ksys-app-template';
import { IAppConfigurationBase } from '../models/app/IAppConfigurationBase';

export const APPLICATION_CLIENT_CONFIGURATIONS: models.api.applicationManager.IApplicationClientConfiguration[] = [
	{
		applicationClientConfigurationId: 1,
		configuration: {
			culture: {
				base: 'Default',
				default: 'en-GB',
			},
			core: {
				inputs: {
					queries: [
						{
							queryId: 'urlPattern',
							queryOptionsType: 'none',
							value: '/:fundId',
						},
						{
							queryId: 'urlFundId',
							queryOptionsType: 'url',
							code: 'param',
							urlPattern: {
								queryOptionsType: 'input',
								key: 'urlPattern',
							},
							key: 'fundId',
						},
					],
				},
				skeletonLoaders: {
					variables: [
						{
							key: 'fillColor',
							value: '#dddddd',
						},
					],
					loaders: [
						{
							id: 'ksys-app-start',
							breakpoints: [
								{
									minWidthInPixels: 0,
									style: {
										height: '86px',
									},
									children: [
										{
											'fill': '{fillColor}',
											'x': '0px',
											'y': '11.90625px',
											'height': '28px',
											'width': '100%',
										},
										{
											'fill': '{fillColor}',
											'x': '0px',
											'y': '59.8125px',
											'height': '18px',
											'width': '100%',
										},
									],
								},
							],
						},
					],
				},
			},
			data: {
				context: {
					entityByType: {
						FUND: {
							inputKey: 'urlFundId',
							clientCodeProperty: 'fund_code',
							include: {
								commentaries: {},
							},
						},
						CLSS: {
							inputKey: 'isin',
							clientCodeProperty: 'isin',
							include: {
								commentaries: {},
							},
						},
					},
				},
			},
		} as IAppConfigurationBase,
		name: 'root',
		applicationClientConfigurationTypeId: 1,
	},
];